import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
// import logo from 'https://jannatonlinebook.live/bg.jpg';
import { Link } from 'react-router-dom';
// import Footer from './jannatbook/Footer';
// import Joinus from './Joinus';

const HomePage = () => {

  return (
    <div className=''>
      <div className='h-[730px] sm:h-[729px] sm:h-screen md:h-screen font-[poppins]'>
      <div className='bg-[url("https://jannatonlinebook.live/bg.jpg")]  opacity-2.0 w-full md:h-screen h-[730px] sm:h-[729px] bg-no-repeat bg-center bg-cover'>
        <div className='w-full flex justify-center items-center'>
          <img className='my-2 p-2 h-full w-[25%] sm:w-[100%] sm:p-2' src="https://jannatbook247.com/assets/img/jannatbook247/logo.gif" alt='logo' />
        </div>
        <div className='w-full flex justify-center items-center my-10 sm:my-20 sm:w-full sm:flex sm:justify-center  sm:items-center'>


          <h1 className='font-[poppins] text-[#ff2e41] sm:text-[36px] sm:w-[100%] sm:p-2 text-center text-[50px]  font-bold w-[50%]'>10% WELCOME BONUS 
            <span className='text-white font-[poppins]'> | 5% ENTRY BONUS</span>  </h1>
        </div>

      </div>
       <div>
         </div> 
      </div>
    <div className='shadow-md m-4 border-4px  bg-[#ecf0f1]'>
          <div className='' >
            <div>
              <p className='p-4 text-justify font-bold text-[22px] text-[#ff2e41]'>"JANNAT" KHELO DIL AND DIMAG SE</p>
             
            </div>

            <div >
              <Link to='https://wa.me/7733003332?text=Hi,I Want ID,JB'><button className='text-white font-bold text-10px bg-[#ff2e41] p-4 rounded-md mx-4 my-4'>Get Your Id Now</button></Link>
            </div>

            <div className='p-4 text-justify'>
              <p className=''>
                Get in touch with Jannat for any Queries, Emergencies, Feedback, or Complaints. We are here to help you 24*7 with our services on WhatsApp.
              </p>
              <br />
              <p className=''>
                Welcome to Jannat Book, we offer you a genuinely unique experience. Besides, We have a global network and provide the best industry experience with a single click.
              </p>
              <br />
              <p className=''>
                Jannat Book is a responsible promoter. We deal in all kinds of fantasy sports games. We have helped 50000+ players to find the best fantasy sports online.
              </p>
              <br />
              <p className=''>
                Our Company Works In A Very Transparent Way. Get Your Demo ID Now
              </p>

            </div>
            <div>
              <p className='font-bold text-[20px] p-4'> WHY CHOOSE US </p>
              <hr />
              <p className=' p-4'>24*7 Support</p>
              <hr />
              <p className=' p-4'>Instant Reply</p>
              <hr />
              <p className=' p-4'>Most Trusted & Secure Platform</p>
              <hr />
              <p className=' p-4'>10 Lac+ Active Users</p>
              <hr />
              <p className=' p-4'>250+ Branches</p>
              <hr />
              <p className=' p-4'>Working Since 2010</p>
            </div>
          </div>
        </div>
        <Link to='https://wa.me/7733003332?text=Hi,I Want ID,JB' ><button className='sm:absolute sm:left-0 sm:fixed sm:sticky bg-[#25D366] rounded-md p-3 absolute left-5 sm:bottom-1 fixed  bottom-1 sticky'>  <FaWhatsapp className='inline h-[30px] w-[30px] ' /> Click Here On Whatsapp</button></Link>
    </div>
    


  )
}

export default HomePage
