import React from 'react'

function Privacy() {
  return (
    <div className='flex flex-col items-center m-5'>
        <div className="center w-3/4 sm:w-96 text-justify bg-[#f8fafc] p-7 flex flex-col gap-2 shadow-2xl">
      <h1 className='text-[30px] font-bold my-2 '>GAME <span className='text-red-600'>CARE</span></h1>
      <p>Bat Ball Line is India's most trusted  promoter, providing a platform for a community of skilled gamers all over the country. Bat Ball Line Book is an Indian  platform that allows users to play , hockey, football, kabaddi, handball, basketball, volleyball, rugby, football and baseball. We understand the needs and requirements for online  in India and therefore provide you with a safe space to enjoy playing online. We strive to provide our users with better and faster results as well as a better and improved online  experience. While providing our users better experience, we also have huge interest in promoting responsible games. The  platforms that we provide are purely a source of entertainment. We want to make sure that users are here for the right reasons. We only promote online , with absolutely no opportunity to win anything of value, such as prizes and money.</p>

      <h1 className='text-[25px] font-bold my-2'>What is ?</h1>
      <p> is a form of virtual or online sports, where a user can create an online ID to play virtual matches or tournaments. The players are selected based on the player's recent performances, the opposition, the playing conditions are a few. Ever since its advent in the late 90s that coincided with the internet boom,  has taken off and is played by millions of people worldwide. In India,  has been an absolute revelation. Over 120 million people are a part of this bandwagon and the number is ever-growing.</p>
      <p>Bat Ball Line Book is a virtual platform for users to select players from a fixture (Cricket/Football/Kabaddi/Basketball/Baseball/Volleyball), earn points based on the player's performance in  and compete against thousands of  players. It is one of India's finest and the most creative  websites. The game interface makes sure that the user has the most amazing gameplay experience. Including players who've had a good run of form have to be preferred over personal choices.</p>

      <h1 className='text-[25px] font-bold my-2'>Secure Platform</h1>
      <p> on Bat Ball Line Book is super safe, secure and legal. Is a skill-based game and isn't considered gambling. It is 100% legal in India. Go to Bat Ball Line Book now and start playing.</p>
      </div>

      <div className="center w-3/4 sm:w-96 bg-[#f8fafc] text-justify p-7 flex flex-col gap-2 shadow-2xl">
                <h1 className='text-[30px] font-bold'>Terms and conditions</h1>
                <p>Disclaimer:- This Website is only for 18+ users. Please Leave This Site If you are from Telangana, Orissa, Assam, Sikkim, and Nagaland. Beware of fraudsters, we only deal via WhatsApp. We only promote online , with absolutely no opportunity to win anything of value, such as prizes and money.</p>
                <p>You must be at least 18 years old, and must not suffer from any disability or impairment which would prevent you from assuming the responsibilities contained in the Agreement, or from getting an online ID for .</p>
                <h5 className="text-[20px] font-bold">1. Introduction</h5>
                <p>1.1 - These user terms & conditions (the "Agreement"), which includes those terms and conditions expressly set out below and those incorporated by reference including the terms of the Privacy Policy and the Game Rules (as defined in section 9 below) is an agreement between you (the "User", "you" and "your"), and Bat Ball Line Book which governs your use of the products and services ("Services") provided by the Company through the Bat Ball Line Book website. By using any of the Services you are agreeing to all the terms of this Agreement.</p>
                <p>1.2 - If any of the terms of the Agreement are determined to be unlawful, invalid, void, or unenforceable for any reason, it shall not affect the validity and enforceability of the remaining terms. Any failure or delay on our part to act or exercise any right or remedy with respect to a breach of any of the terms of this Agreement by you shall not be construed as a waiver of our right to act with respect to the said breach or any prior, concurrent, subsequent or similar breaches.</p>
                <h5 className="text-[20px] font-bold">2. Agreement</h5>
                <p>2.1 - You should ensure that you read, understand, and agree with all the terms and conditions contained in the Agreement, before you may use the Services. You should only use the Services if you have read and understood the Agreement and agree to abide by its terms and conditions. If you have any questions about the Agreement please contact us.</p>
                <p>2.2 - We reserve the right to amend, modify, or change any of the terms of this Agreement at any time, and it is advisable to regularly look at these terms. We shall give notice of such changes ("Notice") either by notification in the manner as the Company may deem fit or by posting the amended Agreement on the Platform. Such modifications could include changes to any of our Game Rules. If you do not agree with the terms of the amended Agreement, you may terminate your use of the Services by notifying us that you wish to terminate your Accounts. Your continued use of the Services will be deemed to constitute your acceptance of the changes to the Agreement.</p>
                <h5 className="text-[20px] font-bold">3. Ownership</h5>
                <p>Use of the Bat Ball Line Book name and any of the other names or marks associated with the Platform, the Services, or the Software is strictly forbidden unless it is used with the express written permission of the Company. The Platform, the Services, and the Software, including its code, documentation, appearance, structure, and organization is an exclusive product of the Company, which retains all rights, tangible and intangible, to the Bat Ball Line Book website, the games therein, software, codes, copies, modifications, or merged parts.</p>
            </div>
            <div className="center w-3/4 sm:w-96 bg-[#f8fafc] text-justify p-7 flex flex-col gap-2 shadow-2xl">
        <h1 className='text-[30px] font-bold'>GAME ADDICTION</h1>
        <p>This game maybe addictive, user discretion is advised.  maybe addictive to a certain extent, we advise to avoid playing the games continuously and take regular breaks while you play.</p>
        <p className='my-5'>Here are some tips you can apply while playing online -</p>
        <div className='flex flex-col gap-7'>
            <p>Set time limit for gaming</p>
            <hr />
            <p>Take regular breaks</p>
            <hr />
            <p>Stop playing during work</p>
            <hr />
            <p>Don't chase losses</p>
            <hr />
            <p>Play with a good frame of mind</p>
            <hr />
            <p>Working Since 2010</p>
            <hr />
        </div>

        <div className='h-40 flex items-center'>These points might be helpful for you while playing and might help you in not being addicted to it.</div>
        
    </div>
      
    </div>
  )
}

export default Privacy
