import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Component/HomePage";
import Terms from "./Component/jannatbook/Terms";
import Gameaddic from "./Component/jannatbook/Gameaddic";
import Privacy from "./Component/jannatbook/Privacy";
import Footer from "./Component/jannatbook/Footer";


function App() {
  return (
    
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path='/terms-condition' element={<Terms />} />
          <Route path='/game-addiction' element={<Gameaddic />} />
          <Route path='/privacy-policy' element={<Privacy />} />
         
        </Routes>
        <Footer />
       
      </Router>
    
  );
}

export default App;
