import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className=''>
    <div className='bg-[#020617] m-0 p-0 h-36 flex justify-center  text-white gap-20 absolute sm:gap-14 sm:p-2 bottom:0 right:0 sm:h-20 w-full'>
      <div className="terms cursor-pointer"> <Link to="/terms-condition">Terms and conditions</Link>  </div>
      <div className="terms cursor-pointer"> <Link to="/game-addiction">Game Addiction</Link>  </div>
      <div className="terms cursor-pointer"> <Link to="/privacy-policy">Privacy Policy</Link>  </div>
    </div>
    </div>
  )
}

export default Footer
