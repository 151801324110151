import React from 'react'

function Gameaddic() {
  return (
    <div className='flex justify-center m-5'>
    <div className="center sm:w-96 w-3/4 bg-[#f8fafc] p-7 flex flex-col gap-2 shadow-2xl text-justify">
        <h1 className='text-[30px] font-bold mb-2 '>GAME ADDICTION</h1>
        <p>This game maybe addictive, user discretion is advised.  maybe addictive to a certain extent, we advise to avoid playing the games continuously and take regular breaks while you play.</p>
        <p className='my-5'>Here are some tips you can apply while playing online -</p>
        <div className='flex flex-col gap-5'>
            <p>Set time limit for gaming</p>
            <hr />
            <p>Take regular breaks</p>
            <hr />
            <p>Stop playing during work</p>
            <hr />
            <p>Don't chase losses</p>
            <hr />
            <p>Play with a good frame of mind</p>
            <hr />
            <p>Working Since 2010</p>
            <hr />
        </div>

        <div className='h-40 flex items-center'>These points might be helpful for you while playing and might help you in not being addicted to it.</div>
        
    </div>
</div>
  )
}

export default Gameaddic
